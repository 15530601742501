import React, { useState, useEffect, useLayoutEffect, Fragment } from "react";
import { navigate } from "gatsby";
import Layout from "../../components/Layout/Layout";
import Login from "../../containers/Login/Login";
import {
  isLoggedIn,
  getUserType,
  setLoginTypeCookies,
} from "../../common/utils/cookie-utils";
import withErrorHandler from "../../hoc/withErrorHandler/withErrorHandler";
import Logout from "../../common/components/Logout/Logout";

const rmLogin = () => {
  const isLogged = isLoggedIn();
  const userType = getUserType();
  // setLoginTypeCookies("rmLogin");
  if (!isLogged)
    return (
      <Layout customTitle="Fincity Employee | ">
        <Login />
      </Layout>
    );
  else if (userType != undefined) {
    if (userType == 1) {
      navigate("/app/dashboard/bp");
    } else {
      navigate("/app/dashboard");
    }
  } else Logout({ hardReload: false, routeTo: "/" });
  return null;
};
export default rmLogin;
